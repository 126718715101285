/* ===================== HELP ======================*/

.call-box {
  background: url(../../img/footer-bg.jpg) no-repeat scroll 0 0 / cover;
  text-align: center;
  border-radius: 5px;
  position: relative;
  padding: 50px 0;
  z-index: 1;
}

.call-box-inner {
  width: 70%;
  margin: 0 auto;
}

.call-box:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8) none repeat scroll 0 0;
  z-index: -1;
  border-radius: 5px;
}

.call-box h2 {
  font-size: 40px;
  color: #fff;
  letter-spacing: 1px;
  margin-bottom: 15px;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  display: inline-block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  line-height: 50px;
}

.call-box h2 span {
  color: #ec3323;
}

.call-box-inner p {
  color: #b6b6b6;
}

.call-number {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 30px;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.call-icon {
  font-size: 50px;
  margin-right: 30px;
  color: #fff;
}

.call-text {
  text-align: left;
}

.call-text p {
  text-transform: capitalize;
  color: #e04639;
  font-size: 17px;
  margin-bottom: 5px;
}

.call-text h4 {
  color: #eee;
  font-size: 20px;
  letter-spacing: 2px;
}

.call-text h4 a {
  color: #eee;
}

@media (min-width: 992px) and (max-width: 1169px) {
  .call-box-inner {
    width: 80%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .call-box-inner {
    width: 100%;
    padding: 0 15px;
  }
  .call-box h2 {
    font-size: 28px;
    line-height: 40px;
  }
}
@media (max-width: 767px) {
  .call-box-inner {
    width: 100%;
    padding: 0 15px;
  }
  .call-box h2 {
    font-size: 28px;
    line-height: 40px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .call-box h2 {
    font-size: 28px;
    line-height: 40px;
  }
  .call-box-inner {
    width: 100%;
    padding: 0 15px;
  }
}
